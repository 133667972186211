<template>
  <div class="contenier-block-method">
    <div class="pay-method row mx-0" :class="[methodPay.id === activeMethod ? 'selected' : '']">
      <div class="col-12 px-0">
        <img
            :src="getPictureUrl(methodPay)"
            :data-src="getPictureUrl(methodPay)"
            :alt="'MagnetBet казино депозит '+ methodPay.name"
            class="pay-method-image"
        >
      </div>
      <div class="col-12 px-0 mt-2">
        <span class="w-100">
           <small v-if="methodPay.min_amount">мин․ {{ methodPay.min_amount }} руб
           <br /> макс․ {{ methodPay.max_amount }} руб </small>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentMethod',
  props: {
    methodPay: Object,
    activeMethod: Number
  },
  methods: {
    getPictureUrl(url) {
      if (url.card_picture) {
        return url.card_picture;
      } else {
        return url.picture ?? url.img
      }
    },
  },

}
</script>

<style scoped>
.pay-method {
  align-items: center;
  background: #1f2229;
  border: 2px solid transparent;
  border-radius: 4px;
  color: #83888b;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  min-height: 110px;
  margin-bottom: 10px;
  /*padding: 0 10px;*/
  padding: 5px;
  white-space: nowrap;
  width: 100%;
  line-height: 1;
  justify-content: start;
}

.pay-method:hover {
  border: 2px solid #535d71;
  color: #fff;
}

.pay-method-image {
  height: 80px;
  max-height: 100%;
  object-fit: contain;
  max-width: 100%;
  border-radius: 4px;
}

.pay-method span {

}

.selected {
  border: 2px solid #535d71;
  color: #fff;
}
</style>
  
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Config from '../config/'

Vue.use(Config)
Vue.use(Vuex)
Vue.use(VueAxios, axios)
axios.defaults.withCredentials = true
axios.defaults.headers.common.Accept = 'application/json'

export default new Vuex.Store({
    state: {
        promotionLobbyBalanceError: '',
        promotionLobbyBalance:null,
        promotionLobby: null,
        games: [],
        providers: [],
        userData: null,
        messages: [],
        flashMessages: null,
        sponsorsUrl: null,
        depositMethods: [],
        wheelBonus: null,
        depositSettings: null,
        withdrawMethods: {
            regular : [],
            vip : []
        },
        withdrawHistory: null,
        depositHistory: null,
        tipData:{},
        lastWithdraws: [],
        winners: null,
        gameWinners: null,
        aliveGames: null,
        liveGames: null,
        liveGameCategories: null,
        balance: 0,
        activeBonuses: null,
        userDataFetched: false,
        terms: null,
        userIdentityData: null,
        mainSettings: {},
        currentLanguage: 'ru',
        clientsLevel: null,
        uDepSum: null,
        ticketTypes: null,
        closedTickets: null,
        openTickets: null,
        homepageWinners: null,
        statistics: null,
        topGames: [],
        mainStatistics: null,
        statisticsForGame:null,
        videos: null,
        slotMachineState: null,
        lobbyURL: null,
        lobbyWinners: null,
        usersLobbyStatus: null,
        isSlotMachineClosed: false,
        busyLobbyBalances: null,
        lobbyAliveGame: null,
        busyLobbies: null,
        promotionLobbiesToken: null,
        freeRound: null
    },

    actions: {
        forceCollectTerminal(context, token) {
            axios.get(Config.baseUrl + '/Api/forceCollectTerminal/' + token)
                .then(({data}) => {
                    console.log(data)
                    if (data.status === 'ok') {
                        context.commit('setPromotionLobbyBalance', data.data)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        getPromotionLobbyBalance(context, token) {
            axios.get(Config.baseUrl + '/Api/getPromotionLobbyBalance/' + token)
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setPromotionLobbyBalance', data.data)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        fetchPromotionLobby(context, token) {
            axios.get(Config.baseUrl + '/Api/getPromotionLobby/' + token)
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setPromotionLobby', data.data)
                        context.commit('setPromotionLobbiesToken', data.data.token)
                    } else if (data.status === 'error') {
                        context.commit('setPromotionLobbyBalanceError', data.msg)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        activatePromotionLobby(context, token) {
            axios.get(Config.baseUrl + '/Api/activatePromotionLobby/' + token)
                .then(({data}) => {
                    console.log(data)
                }).catch(err => {
                console.log(err.message)
            })
        },
        getBusyLobbyBalances(context) {
            axios.get(Config.baseUrl + '/Api/getBusyLobbyBalances')
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setBusyLobbyBalances', data.data.balances)
                        context.commit('setBusyLobbies', data.data.busyLobbies)
                    }
                }).catch(err => {
                console.log(err.message)

            })
        },
        closeSlotMachine (context, machineId) {
            axios.get(Config.baseUrl + '/Api/closeLobby/' + machineId)
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setCloseSlotMachineResult', data.data)
                    }
                }).catch(err => {
                console.log(err.message)

            })
        },
        async fetchClientsLevel(context) {
            axios.get(Config.baseUrl + '/Api/getClientLevels')
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setClientsLevels', data.data.levels)
                        context.commit('setuDepSum', data.data.uDepSum)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        openLobby (context) {
            axios.get(Config.baseUrl + '/Api/createLobby')
                .then(({data}) => {
                    console.log(data)
                    if (data.status === 'ok') {
                        context.commit('setLobbyUrl', data.data.url)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        async fetchTicketTypes(context) {
            axios.get(Config.baseUrl + '/Api/getTicketTypes')
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setTicketTypes', data.data)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        async fetchTopGames(context) {
            axios.get(Config.baseUrl + '/Api/getTopGames')
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setTopGames', data.data)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        async fetchTickets(context) {
            axios.get(Config.baseUrl + '/Api/getTicketLists')
                .then(({data}) => {
                    if (data.status === 'ok') {
                        context.commit('setOpenTickets', data.data.opened)
                        context.commit('setClosedTickets', data.data.closed)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        async fetchUserData(context) {
            context.commit('setUserDataFetched', false)
            return new Promise((resolve) => {
                axios.get(
                    Config.baseUrl + '/Api/getUserInfo',
                    {
                        crossdomain: true
                    }
                ).then(({ data }) => {
                    if (data.data) {
                        let response = data.data
                        let uData = null
                        if (response.userData) {
                            uData = response.userData
                            if (uData) {
                                window.UserInfo = { id: uData.id, login: uData.login }
                                context.commit('setUserData', uData)
                                if (uData.balance) {
                                    context.commit('setUserBalance', uData.balance)
                                }
                            }
                        }
                        resolve(data.data)
                        context.commit('setUserDataFetched', true)
                    }
                }).catch(err => {
                    console.log(err)
                })
            })
        },
        removeUserData(context) {
            context.commit('removeUserData')
        },
        logoutUser(context) {
            axios.get(
                Config.baseUrl + '/Api/logout',
                {
                    crossdomain: true
                }
            ).then(() => {
                context.commit('removeUserData')
                window.location.reload(true)
            }).catch(err => {
                console.log(err)
            })
        },
        fetchSponsorsUrl(context) {
            axios.get(Config.baseUrl + "/Api/getOurProjects")
                .then(({ data }) => {
                    if (data.status === "ok") {
                        context.commit("setSponsorsUrl", data.data.projects)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
        },
        dispatchUserBalance(context, balance) {
            context.commit('setUserBalance', balance)
        },
        fetchUserIdentityData(context) {
            try {
                return new Promise((resolve) => {
                    axios.get(Config.baseUrl + '/Api/getUserIdentityData')
                        .then((resp) => {
                            if (resp.data.status === 'ok') {
                                context.commit('setUserIdentityData', resp.data.data.identityData)
                            }
                        }).catch(err => {
                        console.log(err.message)
                    })
                    resolve()
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        async getMainSettings(context) {

            return new Promise(async (resolve, reject) => {
                const {data} = await axios.get(
                    Config.baseUrl + '/Api/getMainSettings',
                    {crossdomain: true})
                if (data.data) {
                    context.commit('setMainSettings', data.data)
                    resolve(data.data)
                } else {
                    reject(data)
                }
            })
        },
        fetchGames(context) {
            axios.get(Config.baseUrl + '/Api/gamesListv2', {crossdomain: true})
                .then((resp) => {
                    const answer = resp.data
                    if (answer.status && answer.status === 'ok') {
                        context.commit('setGames', answer.data)
                        context.commit('setProviders', answer.data)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        fetchLiveGames(context) {
            axios.get(Config.baseUrl + '/Api/getLiveGames', { crossdomain: true })
                .then((resp) => {
                    const answer = resp.data
                    if (answer.status && answer.status === 'ok') {
                        context.commit('setLiveGames', answer.data)
                    }
                }).catch(err => {
                console.log(err.message);
            })
        },
        fetchGamesByProvidersv2(context, provider) {
            try {
                axios.post(
                    Config.baseUrl + '/Api/gamesListByProvidersv2',
                    {'provider': provider}
                ).then((resp) => {
                    const answer = resp.data
                    if(answer.status === "ok") {
                        context.commit('setGamesv2', answer.data)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        checkForAliveGame(context) {
            return new Promise((resolve) => {
                axios.get(
                    Config.baseUrl + '/Api/checkForAliveGame', {
                        crossdomain: true
                    }
                ).then(({data}) => {
                    if (data.data) {
                        context.commit('setAliveGames', data.data)
                        resolve(data.data)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
            })
        },
        fetchMessages(context) {
            try {
                axios.get(Config.baseUrl + '/Api/getMessages')
                    .then((resp) => {
                        context.commit('setMessages', resp.data)
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchFlashMessages(context) {
            axios.get(
                Config.baseUrl + '/Api/getFlashMessages'
            ).then(({data}) => {
                if (data.status === 'ok') {
                    if (data.data) {
                        context.commit('setFlashMessages', data.data)
                    }
                }
            })
        },
        unsetFlashMessages(context) {
            axios.get(Config.baseUrl + '/Api/unsetFlashMessages'
            ).then(() => {
                context.commit('setFlashMessages', null)
            })
        },
        fetchAvailableDepositMethods(context) {
            axios.get(Config.baseUrl + '/Api/payMethods')
                .then((resp) => {
                    const answer = resp.data
                    if (answer.status && answer.status === 'ok') {
                        context.commit('setDepositMethods', answer.data)
                        context.commit('setDepositSettings', answer.data)
                    }
                }).catch(err => {
                console.log(err.message)
            })
        },
        fetchAvailableWithdrawMethods(context) {
            try {
                axios.get(Config.baseUrl + '/Api/withdrawMethods')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setWithdrawMethods', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchWithdrawHistory(context) {
            try {
                axios.get(Config.baseUrl + '/Api/lastWithdraws')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setWithdrawHistory', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchDepositsHistory(context) {
            try {
                axios.get(Config.baseUrl + '/Api/lastDeposits')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setDepositHistory', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchWinnersList(context) {
            try {
                axios.get(Config.baseUrl + '/Api/getWinners')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setHomepageWinnersList', answer.data.winnersList)
                            context.commit('setWinnersList', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchGameWinners(context, data) {
            try {
                axios.get(Config.baseUrl + '/Api/getGameWinners/' + data.provider + '/' + data.gameId)
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setGameWinners', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchLobbyWinners(context) {
            try {
                axios.get(Config.baseUrl + '/Api/getLobbyWinners')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setLobbyWinners', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        checkLobbyAliveGame(context) {
            try {
                axios.get(Config.baseUrl + '/Api/checkLobbyAliveGame')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setLobbyAliveGame', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchTerms(context) {
            try {
                axios.get(Config.baseUrl + '/Api/getTerms')
                    .then((resp) => {
                        if (resp.data.status && resp.data.status === 'ok') {
                            context.commit('setTerms', resp.data.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchFreeRoundsOnGameLaunch(context, data) {
            try {
                axios.post(
                    Config.baseUrl + '/Api/checkUserFreeRound',
                    data
                ).then((resp) => {
                    console.log(resp)
                }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchWheelBonus(context) {
            try {
                axios.get(Config.baseUrl + '/Api/getWheelBonus')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setWheelBonus', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchStatistics(context) {
            try {
                axios.get(Config.baseUrl + '/Api/dailyStatistics')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setStatistics', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchMainStatistics(context) {
            try {
                axios.get(Config.baseUrl + '/Api/statistics')
                    .then((resp) => {
                        const answer = resp.data

                        if (answer.status && answer.status === 'ok') {
                            context.commit('setMainStatistics', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchStatisticsForGame(context) {
          try {
              axios.get(Config.baseUrl + '/Api/getStatistics')
                  .then((resp) => {
                      const answer = resp.data
                      if (answer.status && answer.status === 'ok') {
                          context.commit('setStatisticsForGame', answer.data.data)
                      }
                  }).catch(err => {
                  console.log(err.message)
              })
          } catch (err) {
              console.log(err.message)
          }
      },
        fetchVideos(context) {
            try {
                axios.get(Config.baseUrl + '/Api/videos')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setVideos', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        fetchSlotMachineStates(context) {
            try {
                axios.get(Config.baseUrl + '/Api/getSlotMachineStates')
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status && answer.status === 'ok') {
                            context.commit('setSlotMachineState', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },
        checkSlotMachineState (context, machine_id) {
            try {
                axios.get(Config.baseUrl + '/Api/checkSlotMachineState/' + machine_id)
                    .then((resp) => {
                        const answer = resp.data
                        if (answer.status) {
                            context.commit('setSlotMachineStatus', answer.data)
                        }
                    }).catch(err => {
                    console.log(err.message)
                })
            } catch (err) {
                console.log(err.message)
            }
        },

        checkUserFreeRound(context) {
            return new Promise((resolve) => {
                axios.get(
                    Config.baseUrl + '/Api/checkUserFreeRoundNew', {
                        crossdomain: true
                    }
                ).then(({data}) => {
                    if (data.data) {
                        context.commit('setFreeRound', data.data)
                        resolve(data.data)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
            })
        },
    },

    mutations: {
        setPromotionLobbyBalanceError(state, error) {
            if (error) {
                state.promotionLobbyBalanceError = error
            }
        },
        setPromotionLobbyBalance(state, balance){
            if (balance) {
                state.promotionLobbyBalance = balance
            }
        },
        setPromotionLobbiesToken(state, token) {
            if (token) {
                state.promotionLobbiesToken = token
            }
        },
        setPromotionLobby(state, data){
            if (data) {
                state.promotionLobby = data
            }
        },
        setBusyLobbyBalances(state, data) {
            if (data) {
                state.busyLobbyBalances = data
            }
        },
        setBusyLobbies(state, data) {
            if (data) {
                state.busyLobbies = data
            }
        },
        setCloseSlotMachineResult(state, data) {
            if (data) {
                state.isSlotMachineClosed = data
            }
        },
        setSlotMachineStatus(state, data) {
            if (data) {
                state.usersLobbyStatus = data
            }
        },
        setLobbyWinners(state, winners) {
            if (winners) {
                state.lobbyWinners = winners
            }
        },
        setVideos(state, videos){
            if (videos) {
                state.videos = videos
            }
        },
        setStatistics(state, stat) {
            if (stat) {
                state.statistics = stat
            }
        },
        setOpenTickets(state, tickets) {
            if (tickets) {
                state.openTickets = tickets
            }
        },
        setClosedTickets(state, tickets) {
            if (tickets) {
                state.closedTickets = tickets
            }
        },
        setSponsorsUrl(state, data) {
            state.sponsorsUrl = data
        },
        setLobbyAliveGame(state, data) {
            state.lobbyAliveGame = data
        },
        setTerms(state, data) {
            state.terms = data.terms
        },
        setGames(state, data) {
            state.games = data.games
        },
        setGamesv2(state, data) {
            state.games[data.provider].games = data.games;
        },
        setLiveGames(state, data) {
            state.liveGames = data.games
        },
        setProviders(state, data) {
            state.providers = data.providers
        },
        setUserData(state, user) {
            state.userData = user
        },
        setUserDataFetched(state, fetched) {
            if (fetched) {
                state.userDataFetched = true
            }
        },
        setTicketTypes(state, ticketTypes) {
            state.ticketTypes = ticketTypes
        },
        setTopGames(state, topGames) {
            state.topGames = topGames
        },
        removeUserData(state) {
            state.userData = null
        },
        setMessages(state, msgs) {
            state.messages = msgs.data
        },
        setFlashMessages(state, params) {
            state.flashMessages = params
        },
        setDepositMethods(state, data) {
            state.depositMethods = data.availableMethods
        },
        setDepositSettings(state, data) {
            state.depositSettings = data.settings
        },
        setClientsLevels(state, levels) {
            Vue.set(state, 'clientsLevel', levels)
        },
        setuDepSum(state, uDepSum) {
            Vue.set(state, 'uDepSum', uDepSum)
        },
        setWithdrawMethods(state, withdrawMethods) {
            state.withdrawMethods.regular = [];
            state.withdrawMethods.vip = [];

            withdrawMethods.methods.map((item) => {
                let type = "regular";
                if(item.is_vip == 1){
                    type = "vip";
                }
                state.withdrawMethods[type].push(item);
              });
            state.tipData = withdrawMethods.tipData
        },
        setWithdrawHistory(state, history) {
            state.withdrawHistory = history.withdraws
        },
        setDepositHistory(state, history) {
            state.depositHistory = history.deposits
        },
        setWinnersList(state, winners) {
            state.winners = winners.winnersList.all
        },
        setHomepageWinnersList(state, winners) {
            state.homepageWinners = winners
        },
        setGameWinners(state, winners) {
            state.gameWinners = winners.data.winners
        },

        setUserBalance(state, balance) {
            state.balance = balance
        },
        setAliveGames(state, data) {
            if (data) {
                state.aliveGames = data.aliveGames
            }
        },
        setUserIdentityData(state, identityData) {
            state.userIdentityData = identityData
        },
        setMainSettings(state, data) {
            state.mainSettings = data
        },
        setWheelBonus(state, data) {
            state.wheelBonus = data.bonus
        },
        setMainStatistics(state, data) {
            state.mainStatistics = data
        },
        setStatisticsForGame(state, data) {
          state.statisticsForGame = data
        },
        setSlotMachineState(state, data) {
            state.slotMachineState = data
        },
        setLobbyUrl(state, data){
            state.lobbyURL = data
        },
        setFreeRound(state, data) {
            if (data && data.id) {
                state.freeRound = data
            }else{
                state.freeRound = null
            }
        },
    },

    getters: {
        getPromotionLobbyBalance(state){
            return state.promotionLobbyBalance
        },
        getPromotionLobby(state){
            return state.promotionLobby
        },
        isSlotMachineClosed(state) {
            return state.isSlotMachineClosed
        },
        getLobbyWinners(state) {
            return state.lobbyWinners
        },
        getLobbyUrl(state) {
            return state.lobbyURL
        },
        getSlotMachineState(state) {
            return state.slotMachineState
        },
        ticketTypes(state) {
            return state.ticketTypes
        },
        topGames(state) {
            return state.topGames
        },
        wheelBonus(state) {
            return state.wheelBonus
        },

        currentLanguage(state) {
            return state.currentLanguage
        },
        games(state) {
            return state.games
        },
        liveGames(state) {
            return state.liveGames
        },
        providers(state) {
            return state.providers
        },
        liveGameCategories(state) {
            return state.liveGameCategories
        },
        userData(state) {
            if (state.userData) {
                return state.userData
            }
            return null
        },
        userDataFetched(state) {
            return state.userDataFetched
        },
        messages(state) {
            try {
                return state.messages
            } catch (err) {
                console.log(err.message)
            }
        },
        getFlashMessages(state) {
            return state.flashMessages
        },
        newMessages(state) {
            return state.messages.filter(i => !i.viewed)
        },
        getDepositMethods(state) {
            try {
                return state.depositMethods
            } catch (err) {
                console.log(err.message)
            }
        },
        getDepositSettings(state) {
            try {
                return state.depositSettings
            } catch (err) {
                console.log(err.message)
            }
        },
        getWithdrawMethods(state) {
            try {
                return state.withdrawMethods
            } catch (err) {
                console.log(err.message)
            }
        },
        getTipData(state) {
            try {
                return state.tipData
            } catch (err) {
                console.log(err.message)
            }
        },
        getWithdrawHistory(state) {
            try {
                return state.withdrawHistory
            } catch (err) {
                console.log(err.message)
            }
        },
        getDepositHistory(state) {
            try {
                return state.depositHistory
            } catch (err) {
                console.log(err.message)
            }
        },
        getWinnersList(state) {
            return state.winners
        },
        getHomepageWinnersList(state) {
            return state.homepageWinners
        },
        getGameWinner(state) {
            return state.gameWinners
        },
        aliveGames(state) {
            return state.aliveGames
        },
        getBalance(state) {
            return state.balance
        },
        getSponsorsUrl(state) {

            return state.sponsorsUrl
        },
        terms(state) {
            return state.terms
        },
        identityData(state) {
            return state.userIdentityData
        },
        getMainSettings(state) {
            return state.mainSettings
        },
        clientsLevel(state) {
            return state.clientsLevel
        },
        uDepSum(state) {
            return state.uDepSum
        },
        closedTickets(state) {
            return state.closedTickets
        },
        openTickets(state) {
            return state.openTickets
        },
        getStatistics(state) {
            return state.statistics
        },
        mainStatistics(state) {
            return state.mainStatistics
        },
        statisticsForGame(state) {
          return state.statisticsForGame
        },
        videos(state){
            return state.videos
        },
        getUsersLobbyStatus(state) {
            return state.usersLobbyStatus
        },
        getBusyLobbyBalances(state) {
            return state.busyLobbyBalances
        },
        getLobbyAliveGame(state) {
            return state.lobbyAliveGame
        },
        getBusyLobbies(state) {
            return state.busyLobbies
        },
        getPromotionLobbiesToken(state) {
            return state.promotionLobbiesToken
        },
        getPromotionLobbyBalanceError(state) {
            return state.promotionLobbyBalanceError
        },
        freeRound(state) {
            return state.freeRound
        }
    }
})

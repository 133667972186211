<template>
  <div v-if="tab" class="col-12 p-0 pl-lg-2">
    <div
      v-if="tab.games && tab.games.length"
      class="row games-list-row no-gutters mx-0">
      <div
        v-for="(game, i) in tab.games"
        v-if="i<gamesCountOnLoad"
        :key="'idx'+i"
        class="game-item-col col-6 col-sm-3 col-lg-2"
        :class="game.system === 'slotegrator' ? 'large-col' : 'large-col'">
        <GamePreview :game="game" :img="game.picture" :isFavorite="tab.icon == 'favorites'" />
      </div>
      <div class="col-12 load-more-are text-center">
        <button name="loadMoreGames"
          v-if="tab.games.length> gamesCountOnLoad"
          class="btn red-btn px-4 mb-4"
          @click="loadMoreGames()"
                v-observe-visibility="loadMoreGames">
          Загрузить еще игр
        </button>
      </div>
    </div>
    <div v-else>
      <h3 v-if="tab.searchText" class="text-center page-title no-results mt-4">
        По запросу
        <span class="no-results-search-text">&#171;{{ tab.searchText }}&#187;</span>
        ничего не найдено
        <span v-if="category"> в категории &#171;{{ category }}&#187;  </span>
        <span v-if="providers.length"> от выбранных провайдеров </span>
        !
      </h3>
      <h3 v-else class="text-center page-title no-results mt-4">
        Нет игр
        <span v-if="category"> в категории &#171;{{ category }}&#187;  </span>
        <span v-if="providers.length"> от выбранных провайдеров </span>
      </h3>
    </div>
  </div>
</template>

<script>
import GamePreview from './GamePreview'

export default {
  name: 'GamesTab',
  props: ['tab', 'providers', 'category'],
  data () {
    return {
      loadMoreCount: 25,
      gamesCountOnLoad: 50
    }
  },
  components: {
    GamePreview
  },
  computed: {
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  methods: {
    loadMoreGames () {
      this.gamesCountOnLoad = this.gamesCountOnLoad + this.loadMoreCount
      return false
    }
  },
  mounted () {
  },
  updated () {
  }
}
</script>

<style>
    .game-item-col:hover:not(.link:hover.is-mobile) {
      transform: translateY(-4%);
      transition: 1s;
    }

    .tab_category_name{
        min-width: 100px;
        max-width: max-content !important;
        height: 40px;
        text-align: left;
        padding: 0 7px;
        background-color: transparent;
        line-height: 39px;
        box-shadow: 0 -8px 5px -5px inset #6f6f6f;
        font-size: 16px;
        float: left;
    }
    .no-results-search-text{
        color : #860019;
        font-size: 20px;
    }
    .no-results {
        font-size: 17px !important;
    }
    @media screen and (min-width: 1450px){
        .large-col{
            -webkit-box-flex: 0;
            flex: 0 0 20% !important;
            max-width: 20% !important;
        }
        .narrow-col{
            -webkit-box-flex: 0;
            flex: 0 0 16.5% !important;
            max-width: 16.5% !important;
        }
    }
    @media screen and (min-width: 1000px) and (max-width: 1449px) {
        .large-col{
            -webkit-box-flex: 0;
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
        .narrow-col{
            -webkit-box-flex: 0;
            flex: 0 0 20% !important;
            max-width: 20% !important;
        }
        .game-item .game-img {
            height: 160px;
        }
        .game-item {
            min-height: 160px;
        }
        .game-info {
            top:160px;
        }
        .games-category{
            width: 33% !important;
            max-width: 33% !important;
            flex: 33% !important;
            min-width: 33% !important;
        }
        .games-provider{
            width: 33% !important;
            max-width: 33% !important;
            flex: 33% !important;
            min-width: 33% !important;
        }
    }
    @media screen and (min-width: 1000px) and (max-width: 1250px){
        .large-col{
            -webkit-box-flex: 0;
            flex: 0 0 25% !important;
            max-width: 25% !important;
        }
        .narrow-col{
            -webkit-box-flex: 0;
            flex: 0 0 20% !important;
            max-width: 20% !important;
        }
        .game-item .game-img {
             height: 120px;
         }
        .game-item {
            min-height: 120px;
        }
        .game-info {
            top:120px;
        }
        .games-category{
            width: 33% !important;
            max-width: 33% !important;
            flex: 33% !important;
            min-width: 33% !important;
        }
    }

    @media screen and (min-width: 769px) and (max-width: 999px) {
        .remove-filter-word{
            display: none;
        }
        .game-item .game-img {
            height: 100px;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 768px) {
      .game-item .game-img {
        height: 170px !important;
        -o-object-fit: contain !important;
        object-fit: contain !important;
        max-height: 170px !important;
      }

      .game-item.alexa_game .game-img {
        object-position: center;
      }
    }
</style>
<style scoped>

</style>
